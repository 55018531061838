<template>
	<div class='c-sticky-cta--original'>
		<a
			class='e-button e-button--filled c-sticky-cta__button'
			:title='title'
			itemprop='url'
			ref='ctaButton'
			:href='link'
		>
			<span itemprop='name'>
				{{ label }}
			</span>
		</a>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'sticky-cta',
	props: {
		label: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		link: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			offsetTop: 0,
			offsetRight: 0,
			clone: null
		};
	},
	computed: {
		...mapGetters({
			culture: 'getCulture'
		})
	},
	mounted() {
		// In order to make the `sticky` work without watching scroll position with JS for the whole content area,
		// we need to clone and pretend the cta to the <main> element, else it will only stick to the current parent.

		// Clone and attach the event
		const clone = this.$el.cloneNode(true);
		clone.classList.remove('c-sticky-cta--original');
		clone.classList.add('c-sticky-cta--clone');
		this.clone = clone;

		// Prepend to the <main> element
		const main = document.querySelector('main');
		main.insertBefore(clone, main.childNodes[0]);
	},
};
</script>
