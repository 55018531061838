import(/* webpackChunkName: "nav" */ "shared/vanilla/nav.js");
import 'shared/vanilla/console';

import { createApp, defineAsyncComponent } from "vue";

import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import store from "shared/store";

import initNav from "shared/vanilla/nav";
import { createEventBus } from 'shared/tools/eventBus';

// Replacement for the $eventBus
const eventBus = createEventBus();

initNav();

import MiniBasketCount from 'shared/components/product/miniBasketCount.vue';
import SampleButton from 'shared/components/product/sampleButton.vue';
import ExternalLinksPopup from 'shared/components/externalLinksPopup.vue';
import ModalDialog from 'shared/components/modalDialog.vue';
import BasketNavTracker from 'shared/components/trackers/basketNavTracker.vue';
import FormPostTracker from 'shared/components/trackers/formPostTracker.vue';
import Spinner from 'shared/components/spinner.vue';
import GlobalSpinner from 'shared/components/globalSpinner.vue';
import PhoneField from 'shared/components/phoneField.vue';
import Breadcrumb from 'shared/components/BreadcrumbElements/Breadcrumb.vue';

import LoginTracker from 'shared/components/trackers/loginTracker.vue';

// Starship - Basket
import FlyOutBasketActivator from "shared/components/basket/flyOutBasketActivator.vue";

import { initPostbackScrollPosition } from "shared/tools/postbackscrollpos.js";
import boot from 'shared/vanilla/boot.js';
import ieNotification from 'shared/tools/ieNotification.js';
import dateTime from "shared/components/dateTime.vue";

import "./main.scss";
import "./contend";

export function initVueApps() {
	mountMenuSampleContainer();
	mountMiniBasket();
	mountMiniBasketCount();
	mountDateTimes();
	mountGlobalUtility();
	mountFaqVideos();
	mountAnimationProvider();
	mountBreadcrumb();
	initCheckoutFlow();
}

initVueApps();
boot();
initPostbackScrollPosition();
ieNotification.init();

export function mountMenuSampleContainer() {
	const menuSampleContainer = document.getElementById('menu-sample-btn');
	if (menuSampleContainer) {
		const app = createApp({
			name: 'menu sampler',
		});
		app.use(store);
		app.component('sample-button', SampleButton);
		app.mount(menuSampleContainer);
	}
}

export function initCheckoutFlow() {
	const formEl = document.querySelector("[data-checkout-item]");
	if (formEl) {
		const app = createApp({
			name: 'checkout item',
		});
		app.use(store);
		app.component('checkout-confirmation', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-confirmation" */ 'multisite/components/checkout/checkoutConfirmation.vue')
		));
		app.component('checkout-modal-login', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-modal-login" */ 'multisite/components/checkout/checkoutModalLogin.vue')
		));
		app.component('checkout-submitted-summary', defineAsyncComponent(
			() => import(/* webpackChunkName: "checkout-submitted-summary" */ 'multisite/components/checkout/checkoutSubmittedSummary.vue')
		));
		app.component('order-quote-confirm', defineAsyncComponent(
			() => import(/* webpackChunkName: "order-quote-confirm" */ 'multisite/components/checkout/orderQuoteConfirm.vue')
		));
		app.component('progress-bar', defineAsyncComponent(
			() => import(/* webpackChunkName: "progress-bar" */ 'shared/components/progressBar/progressBar.vue')
		));
		app.component('delivery-cart', defineAsyncComponent(
			() => import(/* webpackChunkName: "delivery-cart" */ '/Features/GlobalCheckout/SharedComponents/deliveryCart.vue')
		));
		app.mount(formEl);
	}
}

export function mountMiniBasket() {
	const miniBasketMount = document.getElementById("mini-basket");
	if (miniBasketMount) {
		const app = createApp({
			name: 'mini basket',
		});
		app.use(store);
		app.config.globalProperties.$eventBus = eventBus;
		app.component('mini-basket', defineAsyncComponent(
			() => import(/* webpackChunkName: "mini-basket" */ 'shared/components/product/miniBasket.vue')
		));
		app.mount(miniBasketMount);
	}
}

export function mountMiniBasketCount() {
	const miniBasketCountMounts = document.querySelectorAll(".c-nav-basket");
	miniBasketCountMounts.forEach(miniBasketCountMount => {
		if (miniBasketCountMount) {
			const app = createApp({
				name: 'mini basket'
			});
			app.use(store);
			app.config.globalProperties.$eventBus = eventBus;
			app.component('fly-out-basket-activator', FlyOutBasketActivator);
			app.component('mini-basket-count', MiniBasketCount);
			app.mount(miniBasketCountMount);
		}
	});
}

export function mountDateTimes() {
	const dateTimes = document.querySelectorAll('.date-time');
	if (dateTimes) {
		dateTimes.forEach(dateTimeEl => {
			const app = createApp({
				name: 'dateTime',
			});
			app.use(store);
			app.component('date-time', dateTime);
			app.mount(dateTimeEl);
		});
	}
}

export function mountGlobalUtility() {
	const globalUtilityMount = document.getElementById("global-utility-mount");
	if (globalUtilityMount) {
		const app = createApp({
			name: 'global utility'
		});
		app.use(store);
		app.component('external-links-popup', ExternalLinksPopup);
		app.component('modal-dialog', ModalDialog);
		app.component('basket-nav-tracker', BasketNavTracker);
		app.component('form-post-tracker', FormPostTracker);
		app.component('login-tracker', LoginTracker);
		app.component('spinner', Spinner);
		app.component('phone-field', PhoneField);
		app.component('global-spinner', GlobalSpinner);
		app.mount(globalUtilityMount);
	}
}

export function mountFaqVideos() {
	const faqVideos = document.querySelectorAll(".c-faq__figure");
	if (faqVideos.length) {
		faqVideos.forEach(faqVideo => {
			const app = createApp({
				name: 'faq video'
			});
			app.use(store);
			app.component('faq-video', defineAsyncComponent(() => import(/* webpackChunkName: "faq-video" */ "shared/components/faq/faqVideo.vue")));
			app.mount(faqVideo);
		});
	}
}

export function mountAnimationProvider() {
	const animationProviderComponent = document.querySelector(
		"animation-provider"
	);
	if (animationProviderComponent) {
		const app = createApp({
			name: 'animation provider',
		});
		app.component('animation-provider', defineAsyncComponent(() => import(/* webpackChunkName: "animation-provider" */ "multisite/components/animationProvider.vue")));
		app.mount(animationProviderComponent);
	}
}

export function mountBreadcrumb() {
	const breadcrumb = document.getElementById('breadcrumb');
	if (breadcrumb) {
		const app = createApp({
			name: 'breadcrumb app', // if the app is called breadcrumb like the component the app will crash with a call stack exceeded error
		});
		app.component('breadcrumb', Breadcrumb);
		app.mount(breadcrumb);
	}
}
